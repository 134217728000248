<template>
    <zw-input-group v-model="inputVal.return_price"
                    name="return_price"
                    disable-label
                    type="number"
                    size="sm"
                    @change="savePosition('return_price',inputVal)"
    ></zw-input-group>
</template>

<script>
export default {
    name: 'ReturnPriceColumn',
    props: {
        value: [String, Number, Object],
        savePosition: [Function]
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>